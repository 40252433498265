/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
@use '@angular/material'as mat;

// @import "~ngx-sharebuttons/themes/default.scss";

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* Theme variables */
@import "./theme/custom-components.scss";



/* Theme styling */
 * {
   //font-family: 'Comfortaa', cursive;
   font-family: 'Roboto', sans-serif;
 }

*::-webkit-scrollbar {
  display: none;
}

/* START Custom Utilities */

.no-padding {
  padding: 0em;
}

 .padding-y-s {
   padding: 1em 0em !important;
 }

 .padding-y {
   padding: 2em 0em !important;
 }

 .padding-s {
   padding: 1em !important;
 }


 .padding {
   padding: 2em !important;
 }

 .padding-left-s {
   padding-left: 1em !important;
 }

 .padding-left {
   padding-left: 2em !important;
 }

 .padding-top-xs {
   padding-top: 0.5em !important;
 }

.padding-top-xxs {
  padding-top: 0.25em !important;
}

 .padding-top-s {
   padding-top: 1em !important;
 }

 .padding-top {
   padding-top: 2em !important;
 }

.padding-bottom-xs {
  padding-bottom: 0.5em !important;
}

.padding-bottom-xxs {
  padding-bottom: 0.25em !important;
}

.padding-bottom-s {
  padding-bottom: 1em !important;
}

.padding-bottom {
  padding-bottom: 2em !important;
}

.padding-bottom-m {
  padding-bottom: 3em !important;
}

.padding-bottom-xl{
  padding-bottom: 4em !important;
}

.no-margin-bottom {
  margin-bottom: 0em;
}

.no-margin {
  margin: 0em;
}

.no-flex {
  display: block !important;
}

.margin-top-xs {
  margin-top: 0.5em !important;
}

.margin-top-s {
  margin-top: 1em !important;
}

.margin-top {
  margin-top: 2em !important;
}

.margin-top-l {
  margin-top: 3em !important;
}

.margin-top-xl {
  margin-top: 4em !important;
}

.margin-top-xxs {
  margin-top: 0.25em !important;
}

.margin-right-xs {
  margin-right: 0.5em !important;
}

.margin-right-xxs {
  margin-right:  0.2em !important;
}

.margin-right-s {
  margin-right: 1em !important;
}

.margin-right {
  margin-right: 2em !important;
}

.margin-right-l {
  margin-right: 3em !important;
}

.margin-left-s {
  margin-left: 1em !important;
}

.margin-left-xs {
  margin-left: 0.5em !important;
}
.margin-left-xxs {
  margin-left: 0.2em !important;
}

.margin-left {
  margin-left: 2em !important;
}

.margin-left-l {
  margin-left: 3em !important;
}

.margin-left-opposite-s {
  margin-left: -1em !important;
}

.margin-bottom-xs {
  margin-bottom: 0.5em !important;
}

.margin-bottom-xxs {
  margin-bottom: 0.25em !important;
}

.margin-bottom-s {
  margin-bottom: 1em !important;
}

.margin-bottom {
  margin-bottom: 2em !important;
}

.margin-bottom-l {
  margin-bottom: 3em !important;
}

.flex {
  display: flex;
}

.flex-1 {
  flex: 1;
}

.flex-split {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-columns {
  display: flex;
  flex-direction: column;
}

.display-right {
  float: right;
}

.full-width {
  width: 100%;
}

.max-height{
 height: 100%;
}

.center {
  text-align: center !important;
}

.center-self {
  align-self: center;
}

.footer-buttons {
  position: fixed;
  left: 15px;
  bottom: 1em;
  right: 15px;
}

.flex-centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-vertically-centered {
  display: flex;
  align-items: center;
}

.flex-split-even {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.flex-pin-right {
  display: flex;
  justify-content: right;
  align-items: center;
}

.flex-pin-left {
  display: flex;
  justify-content: left;
  align-items: center;
}


.vertically-centered {
  margin-top: auto;
  margin-bottom: auto;
}

.col-span-2 {
  grid-column: 1 / span 2;
}

.col-span-3 {
  grid-column: 1 / span 3;
}

.col-span-4 {
  grid-column: 1 / span 4;
}

span.small {
  font-size: var(--ion-font-size-s) !important;
}

span.medium {
  font-size: var(--ion-font-size) !important;
}
span.extra-small {
  font-size: var(--ion-font-size-xs) !important;
}
span.large {
  font-size: var(--ion-font-size-l) !important;
}

span.xlarge {
  font-size: var(--ion-font-size-xl) !important;
}

.bold {
  font-weight: 700 !important;
}

.italics {
  font-style: italic;
}

.break-text-line {
  white-space: break-spaces !important;
}

.amount {
  white-space: nowrap;
}

.grey {
  color: var(--ion-color-grey);
}

[color="primary"] {
  color: var(--ion-color-primary);
}

[color="black"] {
  color: var(--ion-text-black);
}

[color="success"] {
  color: var(--ion-color-success) !important;
}

[color="warning"] {
  color: var(--ion-color-warning) !important;
}

[color="grey"] {
  background-color: var(--ion-color-grey-light);
}

[color="error"] {
  color: var(--ion-color-danger) !important;
}

[color="medium"] {
  color: var(--ion-color-medium) !important;
}

[color="gold"] {
  color: var(--ion-color-gold) !important;
}

.light-grey {
  color: var(--ion-color-medium);
}

.orange {
  color: var(--ion-color-medium);
}

/* END Custom Utilities */


//
// Customized elements
// a.button
// div.header
// .info-grid
// span.tags and .tags-list
// .loading-placeholder
// button.loading
// body.scanner-active - needed for the plugin to work
// ckeditor

//

button img {
  // Reduce size of images inside buttons as prefix or suffix
  height: 1.5em;
}

table td.image {
  // Reduce size of images inside table
  width: 75px;
  height: 50px;
  margin-right: 0.5em;
}

h1.introjs-tooltip-title {
  color: black
}

div.introjs-tooltiptext {
  color: black;
}

div.introjs-tooltipbuttons {
  a.introjs-disabled {
    display: none;
  }
}

a.button {
  display: inline-flex;
  font-weight: bold;
  text-decoration: none;
  color: var(--ion-color-primary);
  padding: 0em 1em;
  font-size: 14px;
  align-items: center;
  border-radius: var(--ion-border-radius);
}

.two-cell-grid {
  display: grid;
  grid-column: 2;
  column-gap: 1em;
  grid-template-columns: 1fr 1fr;
}
.three-cell-grid {
  display: grid;
  grid-column: 3;
  column-gap: 1em;
  grid-template-columns: 1fr 1fr 1fr;
}

.four-cell-grid {
  display: grid;
  grid-column: 4;
  column-gap: 1em;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}


.split-grid{
  display: grid;
  grid-template-columns: 1fr 1fr;
}



a.button span {
  margin-right: 0.5em;
}

.button:hover {
  cursor: pointer;
}

.clickable {
  cursor: pointer;
}

// header
div.header {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

//info grid
.info-grid {
  display: grid;
  grid-template-columns: 1fr 2fr;
  column-gap: 1em;

  span.key {
    color: var(--ion-color-grey);
  }

  span.value {
    font-weight: 700;
  }
}

.vertical-grid{
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 0.25em;
}

span.key {
  color: var(--ion-color-grey);
}

span.value {
  font-weight: 700;
}

.block-display {
  display: block;
}

div.tags-list {
  display: flex;
  overflow-y: hidden;
}


.icon-tag {
  padding-right: 12px !important;

  .tag-prefix-icon {
    margin-left: 0px !important;
    margin-right: 2px !important;
  }
}

.icon-tag.primary {
  color: var(--ion-color-primary) !important;
  background-color: var(--ion-field-background) !important;
}

div.icon-info-list {
  padding-top: 5px;
  display: flex;
  overflow-y: hidden;
}

div.icon-info {
  display: flex;
  justify-content: center;
  margin-right: 1em;

  mat-icon {
    color: var(--ion-color-primary);
    margin-top: auto;
    margin-bottom: auto;
    font-size: 20px;
  }
  span.icon{
    margin-top: auto;
    margin-bottom: auto;
    font-size: 20px;
    color: var(--ion-color-medium);
    margin-right: 0.25em;
  }

  span {
    text-transform: uppercase;
    font-weight: bold;
  }
}

span.simple-badge {
  display: inline-block;
  padding: 0.5em 0.5em;
  border-radius: var(--ion-border-radius);
  text-transform: uppercase;
  font-size: var(--ion-font-size-s);
  font-weight: 800;
  color: var(--ion-color-primary);
  background-color: var(--ion-color-primary-contrast);
}
span.simple-badge[color="warning"] {
  background-color: rgb(255, 123, 0);
  color: #FF7A00;
}

span.simple-badge[color="success"] {
  background-color: rgb(0, 156, 16);
  color: #009C10;
}


div.loading-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  height: -webkit-fill-available;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

mat-icon.progress {
  animation: spinner 1.5s linear infinite;
}

button.loading {
  cursor: default;
  pointer-events: none;

  // buttons with background
  &.mat-flat-button.mat-primary {
    span.mat-button-wrapper {
      color: var(--ion-color-primary); // hide button text during loading
    }

    span.mat-button-wrapper::before {
      border: 2px solid transparent;
      border-top-color: var(--ion-color-light);
    }
  }

  // text buttons
  span.mat-button-wrapper {
    color: var(--ion-background-color); // hide button text during loading
    display: inline-block;
    position: relative;
  }

  span.mat-button-wrapper::before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border: 2px solid var(--ion-color-grey-light);
    border-top-color: var(--ion-color-dark);
    animation: spinner .8s linear infinite;
  }
}

body.scanner-active {
  background: transparent;

  ion-router-outlet {
    display: none;
  }

  span#barcode-scanner-close {
    display: block;
  }
}

ckeditor {
  max-height: 20vh !important;
  border-radius: 1em;
  overflow-y: scroll;
  display: block;
}

body.dark-theme ckeditor {
  color: black;
}

body.dark-theme .chip-tag {
  background-color: rgba(90, 136, 255, 0.1) !important;
  color: rgb(152, 193, 217) !important;
  margin-right: 6px;
}

span#barcode-scanner-close {
  // interface to allow abort barcode scanner camera open
  position: absolute;
  top: 4em;
  right: 1em;
  display: none;
  font-size: 130%;
  font-weight: bold;

  &:hover {
    cursor: pointer;
  }
}

//
// ionic changes
//
ion-content {
  --padding-start: 15px;
  --padding-end: 15px;
}

ion-header {
  background-color: var(--ion-background-color);

  &::after {
    display: none; // remove bottom border
  }

  ion-toolbar {
    --background: transparent;
    --border-width: 0 !important;
    padding-bottom: 0.5em;

    ion-title {
    }

    ion-title.help {
      width: fit-content;

      &::after {
        font-family: 'Material Icons';
        content: "help";
        margin: 0px 0px 0px 10px;
        color: var(--ion-color-grey);
        text-decoration: none;
      }
    }

    ion-buttons[slot="end"]>button {
      margin-right: 1em !important;

      @media screen and (min-width: 900px) {
        color: var(--ion-text-color);
      }
    }

    ion-menu-button {
      color: var(--ion-color-dark);
    }
  }
}

ion-modal {
  background-color: #00000054;
  color: var(--ion-color-dark) !important;

  &::part(content) {
    border-radius: 10px;
  }

  &.full-screen::part(content) {
    width: 100%!important;
    height: 100%!important;
    max-width: 100% !important;
    max-height: 100% !important;
    border-radius: 0;
  }

  &.flexible-y::part(content) {
    border-radius: 10px;
  }
  
  &.full-screen-y::part(content) {
    height: 100%;
    border-radius: 10px;
  }

  ion-header {
    border-bottom: 1px solid var(--ion-color-grey-lightv2);

    ion-toolbar {
      padding-bottom: 5px;
      padding-left: 17px;

      &:first-of-type {
        padding-top: var(--ion-safe-area-top, 15);
      }
    }

    ion-title {
      position: relative;
      padding-left: 0;
      text-align: left;
    }

    ion-button {
      --color: var(--ion-color-grey) !important;
      --background-hover: transparent !important;
      --background-focused: transparent !important;
      --background-activated: transparent !important;

      &:hover {
        cursor: pointer;
      }
    
      &:active {
        transform: scale(0.95);
      }
    }
  }

  ion-content {
    --padding-top: 20px;
    --padding-bottom: 20px;

  }
  
  ion-footer {
    display: flex;
    justify-content: space-between;
    padding-top: 15px;
    padding-bottom: calc(var(--ion-safe-area-bottom) + 10px);
    padding-left: calc(var(--ion-safe-area-left) + 15px);
    padding-right: calc(var(--ion-safe-area-left) + 15px);

    button {
      width: inherit;
      margin-bottom: 0.5em
    }

    button:not(:first-child) {
      margin-left: 1em;
    }

    &::before {
      display: none; // remove bottom border
    }
  }

  /*
   * deprecated to refactor and remove
  */
  &.custom::part(content) {
    height: fit-content;
    max-width: 80%; // don't stretch full screen x axis on mobile
  }

  &.custom .ion-page {
    padding: 0 2em;
    margin-bottom: 1em;
  }

  div.mat-dialog-actions {
    margin-top: 2em;
  }
}

//CARD STYLING
ion-card {
  margin: 0;
  color: var(--ion-color-dark);
  box-shadow: var(--ion-box-shadow);
  border-radius: var(--ion-border-radius);

  .card-content-md,
  .card-content-ios {
    font-size: var(--ion-font-size);
    font-weight: unset;
  }

  h1 {
    font-size: var(--ion-font-size-l) !important;
    font-weight: bold !important;
    margin-bottom: 0.5em !important;
  }

  span.suffix {
    display: inline-block;
    width: 100%;
    padding: 0.5em;
  }

  span.barcode {
    background-color: var(--ion-color-primary);
    color: var(--ion-color-primary-contrast);
    text-align: center;
    text-transform: uppercase;
  }

   .title {
     text-transform: uppercase;
     font-weight: 800;
   }

  .alt-title {
    text-transform: uppercase;
    font-weight: 500;
  }

  .sub-title {
    text-transform: uppercase;
    color: var(--ion-color-grey);
    font-weight: 600;
  }

  .sub-title-small {
    text-transform: uppercase;
    font-size: 10px;
    color: var(--ion-color-grey);
    font-weight: 600;
  }
}

ion-card.template1 {
  div.container {
    display: flex;
  }

  div.container .card-image-wrapper {
    width: auto;
  }

  div.container img {
    margin-top: auto;
    margin-bottom: auto;
    max-width: 7em;
  }



  ion-card-content {
    display: flex;
    align-items: center;
    flex-grow: 1;

    div.section1 {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }
  }
}

//PRODUCT CARD STYLING

div.product-card-image-wrapper-container {
  display: flex;
}

div.product-card-image-wrapper {
  display: flex;
  width: 100%;
  flex-shrink: 0;
  max-height: 10em;
  background-color: white;

  img {
    width: 100%;
    max-width: 100%;
    max-height: 10em;
    object-fit: contain;
    margin: auto;
  }
}

div.product-card-image-wrapper-sm {
  @extend .product-card-image-wrapper;
  width: 25%;
}

::ng-deep ion-list {
  background: transparent !important;
}

.feature-card {
  display: flex;
  justify-content: space-between;
  &::part(native) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .icon-container {
    border-radius: 50%;
    width: 48px;
    height: 48px;
    padding: 12px;
    background-color: var(--ion-color-primary);
    color: var(--ion-color-primary-contrast);
  }


  .locked{
    background-color: var(--ion-color-grey);
    color: var(--ion-color-grey-light);
  }

  .completed{
    background-color: var(--ion-color-success);
  }


  ion-card-content {
    .title {
      text-transform: none;
      font-size: var(--ion-font-size-l);
    }
  }
}

/**
  FORM STYLING
 */

.form-section-toolbar {
  display: flex;
  justify-content: space-between;

}

.centred-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

ion-action-sheet {
  div.action-sheet-group {
    border-radius: var(--ion-border-radius) var(--ion-border-radius) 0 0;
  }

  div.action-sheet-title {
    text-transform: capitalize;
    color: var(--ion-color-dark) !important;
    font-weight: bold;
    padding-bottom: 2em !important;
  }

  button.action-sheet-button {
    text-transform: capitalize;
  }

  button.disabled {
    cursor: not-allowed;
    color: var(--ion-color-grey-light) !important;
  }
}

ion-list {
  background: transparent !important;
  padding: 8px !important;
}

// toast
ion-toast {
  --box-shadow: var(--ion-box-shadow);
  --color: var(--ion-text-primary);
  --background: var(--ion-background-color);
  
  &::part(container) {
    align-items: center;
  }

  &::part(header) {
    text-transform: capitalize;
  }

  &::part(message) {
    color: var(--ion-color-grey);
  }

  &.success::part(icon) {
    color: var(--ion-color-success);
  }

  &.warning::part(icon) {
    color: var(--ion-color-warning);
  }

  &.error::part(icon) {
    color: var(--ion-color-danger);
  }

  &::part(button) {
    color: var(--ion-color-grey);
  }
}

.title-image-card {
  ion-card-header {
    text-align: left;
  }
}

ion-segment {
  --background: transparent;
  margin-bottom: 0.5em;

  ion-segment-button.segment-button-checked {
    font-weight: bold;
    color: var(--ion-color-dark);

  }

  ion-segment-button:not(.segment-button-checked) {
    color: var(--ion-color-medium);
  }

  ion-segment-button {
    --indicator-color: var(--ion-color-light);
    margin: 0 0.5em;
    min-width: auto !important;
  }

  ion-segment-button:hover {
    color: var(--ion-color-dark);

    &::part(native) {
      opacity: 1;
    }
  }

  ion-segment-button::before {
    display: none;
  }

  &>* {
    font-size: var(--ion-font-size);
    text-transform: capitalize;
  }
}

//
// angular material changes
//

// Reduce size of mat-form field
mat-form-field {

  min-width: 0;
  width: 100%;
  font-size: var(--ion-font-size) !important;

  &.mat-form-field-appearance-fill {
    div.mat-form-field-underline {
      display: none; //remove underline line for fill
    }

    div.mat-form-field-flex {
      border-radius: 10px;
      padding: 0.5em 0.75em 0 0.75em;
    }

    div.mat-form-field-infix {
      padding: 0 0 0.5em 0;
    }
  }

  &.mat-form-field-invalid {
    div.mat-form-field-flex {
      border: 1px solid red;
    }
  }


  div.mat-form-field-prefix {
    margin-right: 0.5em;
  }

  div.mat-form-field-suffix {
    margin-left: 0.5em;
  }

  .mat-form-field-subscript-wrapper {
    margin-top: 1em !important;
    position: relative;
  }

}


.disabled {
  opacity: 0.4;
}

mat-hint {
  font-size: 12px; // hardcode it so that it can be used outside mat-form-field (for radio group for example)
  color: var(--ion-color-danger) !important;

  &.info {
    color: var(--ion-color-grey) !important;
  }

  &.warning {
    color: var(--ion-color-warning) !important;
  }

  &.success {
    color: var(--ion-color-success) !important;
  }
}

mat-slide-toggle.form-field {
  width: 100%;
  font-size: var(--ion-font-size) !important;
  margin-bottom: 1.34375em; // same as mat-form-field bottom padding
  padding: 1em;
  border-radius: 10px;
  background-color: var(--ion-field-background);

  &.mat-checked {
    span.mat-slide-toggle-bar {
      background-color: var(--ion-color-primary-dark) !important;
    }
  }

  &.mat-slide-toggle {
    height: auto;
  }

  label {
    flex-direction: row-reverse !important;
    justify-content: space-between;

    .mat-slide-toggle-thumb-container span.mat-slide-toggle-thumb {
      background-color: var(--ion-color-primary);
    }
  }
}

// Card Information layout
.card-item-info {
  display: grid;
  grid-template-columns: 3fr 1fr;

  .info-body-two {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .info-body-three {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .info-pair {
    text-align: start;
    display: grid;
    grid-template-rows: 1fr 1fr;
    font-size: 12px;

    .title {
      text-transform: uppercase;
      font-weight: bold;
      color: var(--ion-color-primary);
    }
  }

  .horizontal-appendix {
    color: var(--ion-color-primary);
    font-size: 16px;
    font-weight: bold;
    display: flex;
    justify-content: end;

    div {
      margin-top: auto;
      margin-bottom: auto;
    }

    mat-icon {
      margin-top: auto;
      margin-bottom: auto;
    }
  }

}




// EMPTY MESSAGE STYLING - used on fliproom-list and in other empty ion-list areas
div.no-data-con {
  height: 50px;
  width: 100%;

  p {
    width: 100%;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    color: var(--ion-color-grey-light);
    font-style: italic;

    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
  }
}

mat-progress-bar {
  border-radius: 2px;

  div.mat-progress-bar-buffer {
    background-color: var(--ion-color-grey-light) !important;
  }

  .mat-progress-bar-fill::after {
    border-radius: 2px;
  }
}


div.table-wrapper {
  /** used to handle when too many rows*/
  width: 100%;
  box-shadow: var(--ion-box-shadow);
  padding: 0 !important;
  height: 80vh;
  overflow-y: scroll;
  border-radius: var(--ion-border-radius);

  &::-webkit-scrollbar {
    display: none;
  }
}

mat-paginator {

  div.mat-paginator-container {
    align-items: baseline;
  }

  .mat-form-field-appearance-legacy .mat-form-field-underline {
    height: 0;
  }

  div.mat-paginator-page-size-label {
    font-weight: bold;
    color: var(--ion-color-dark);
  }

}

.card-image-wrapper {
  display: flex;
  background-color: white;
}


table {
  width: 100%;
  background: var(--ion-card-background) !important;
  overflow: auto;

  thead {
    position: sticky;
    top: 0;
    background: var(--ion-card-background) !important;
    z-index: 99999;

    th.mat-header-cell {
      border: none;
    }
  }

  tbody {
    tr {
      &:nth-child(odd) {
        background: var(--ion-color-light-tint) !important;
      }

      td {
        padding-right: 1em !important;
        border-bottom: 0px !important;
        border-top: 1px solid rgba(255, 255, 255, 0.12);
      }
    }
  }

}

table.custom {
  tr.advanced-filters {
    input {
      background-color: var(--light-grey);
      padding: 0.5em;
      border-radius: 1em;
    }

    div.date-input {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    div.string-input {
      display: flex;
      align-items: center;
      color: grey;
    }

    div.number-input {
      display: flex;
      justify-content: space-between;

      input {
        width: 33%;
      }
    }
  }



}

/*
 *
 * VERSION 2 - v2
*/

/**
'basic-theme' class ensures that the modal has the following styles:
 - Make modal primary colour black not blue
 - make background colours white not adaptive to light and dark theme
 - make text colours black
 - make form fields grey
 */
.basic-theme, body.dark-theme .basic-theme {
  --ion-color-primary: black;
  --ion-background-color: white;
  --ion-text-color: black;
  --ion-toolbar-color: black;
  --ion-color-dark: black;

  ion-card, .mat-form-field .mat-form-field-flex {
    background-color: #EEEEEE;
    color: black;
  }

  .mat-input-element:disabled, .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
    color: rgba(0, 0, 0, 0.38);
  }
  .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
    color: rgba(0, 0, 0, 0.38);
  }
  .mat-form-field-label, .mat-input-element::placeholder {
    color: rgba(0, 0, 0, 0.6);
  }
  .mat-select-value {
    color: black;
  }
  .mat-select-arrow {
    color: rgba(0, 0, 0, 0.54);
  }
  .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
    color: rgba(0, 0, 0, 0.38);
  }

  .mat-stroked-button:not(.mat-button-disabled) {
    border-color: rgba(0, 0, 0, 0.12);
  }
  .mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
    background-color: rgba(0, 0, 0, 0.12);
  }
  .mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
    color: rgba(0, 0, 0, 0.26);
  }
}
