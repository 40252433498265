// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  --text-color: black;
  --text-color-contrast: #dedede;

  --ion-background-color: #ffffff;
  --ion-background-color-rgb: 255, 255, 255;
  --ion-background-secondary: rgb(240, 240, 240);

  --ion-card-background: rgb(255, 255, 255);
  --ion-card-background-alt: #f7f7f7;
  --ion-field-background: rgba(0,0,0,.04);
  --ion-field-font-color: #626262;
  --ion-border-radius: 10px;

  --ion-text-primary: black;
  --ion-text-secondary: #656565;

  --ion-text-color: black;
  --ion-text-black: black;
  --ion-text-color-rgb: 0,0,0;

  --ion-font-size-xs: 8px;
  --ion-font-size-s: 10px;
  --ion-font-size: 14px;
  --ion-font-size-l: 16px;
  --ion-font-size-xl: 24px;


  --ion-color-grey-light: rgb(200 200 200);
  --ion-color-grey-lightv2: rgb(240, 240, 240);
  --ion-color-grey-light-contrast: #0a0a0a40;
  --ion-color-grey: rgb(139, 139, 139);
  --ion-color-grey-dark: #333333;
  --ion-border-color: #dedfe1;

  --ion-box-shadow: rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;;

  --ion-orange-color: #fa8801;


  /** primary **/
  --ion-color-primary: #0046fd;
  --ion-color-primary-rgb: rgb(0, 70, 253);
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;
  --ion-color-primary-dark: #2520E3;


  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;
  --ion-color-danger-dark: #cd041f;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #dedfe1;
  --ion-color-light-tint: #f5f6f9;
  --border-radius: 10px;

  /** gold **/
  --ion-color-gold: #FCD401;
  --ion-color-gold-rgb: 252, 212, 1;
  --ion-color-gold-contrast: #000000;
  --ion-color-gold-contrast-rgb: 0, 0, 0;
  --ion-color-gold-shade: #debb01;
  --ion-color-gold-tint: #fcd81a;


  --ck-border-radius: 10px !important;
  --ck-color-base-border: var(--ion-background-color) !important;
  --ck-color-toolbar-border: var(--ion-background-color) !important;
  --ck-color-base-active: var(--ion-color-primary) !important;
}

@media (prefers-color-scheme: dark) {
  /*
   * Dark Colors
   * -------------------------------------------
   */

  body {
    --text-color: #ffffff;
    --text-color-contrast: #000000;

    --ion-field-background: rgba(255, 255, 255, 0.1);
    --ion-color-grey-light: rgb(200 200 200);
    --ion-color-grey-light-contrast: #0a0a0a40;
    --ion-field-font-color: #BDBDBD;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244,245,248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0,0,0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152,154,162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0,0,0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34,36,40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255,255,255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;

    --ion-border-color: #656565;
    --ion-border-radius: 10px;


  }

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  .ios body {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0,0,0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;
    --ion-item-background: #000000;

    --ion-card-background: #1c1c1d;
    --ion-card-background-alt: #1c1c1d;
  }

  .ios ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }

  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

  .md body {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18,18,18;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #1e1e1e;

    --ion-toolbar-background: #1f1f1f;

    --ion-tab-bar-background: #1f1f1f;

    --ion-card-background: #1e1e1e;
    --ion-card-background-alt: #1e1e1e;
  }
  .ck {
    --ck-color-base-border: var(--ion-background-color);
    --ck-color-toolbar-border:var(--ion-background-color);

    --ck-color-button-default-background: var(--ck-custom-background);
    --ck-color-button-default-hover-background: hsl(270, 1%, 22%);
    --ck-color-button-default-active-background: hsl(270, 2%, 20%);
    --ck-color-button-default-active-shadow: hsl(270, 2%, 23%);
    --ck-color-button-default-disabled-background: var(--ck-custom-background);

    --ck-color-button-on-background: var(--ck-custom-foreground);
    --ck-color-button-on-hover-background: hsl(255, 4%, 16%);
    --ck-color-button-on-active-background: hsl(255, 4%, 14%);
    --ck-color-button-on-active-shadow: hsl(240, 3%, 19%);
    --ck-color-button-on-disabled-background: var(--ck-custom-foreground);


    --ck-color-dropdown-panel-background: hsl(270, 1%, 22%);
    --ck-color-dropdown-panel-border: hsl(270, 1%, 22%);
    --ck-color-list-background: var(--ck-custom-background);

    --ck-color-list-button-hover-background: var(--ion-color-step-250);
    --ck-color-list-button-on-background: var(--ck-color-base-active);
    --ck-color-list-button-on-background-focus: var(--ck-color-base-active-focus);
    --ck-color-list-button-on-text: var(--ck-color-base-background);
  }

}


/*
 *
 *  ANGULAR THEMING
 *
 */
@import '~@angular/material/theming';
@include mat-core();

// apply angular theme http://mcg.mbitson.com/#!?mcgpalette0=%233f51b5
$primary-palette: (
  100: var(--ion-color-primary-shade), //lighter
  500: var(--ion-color-primary), //default
  700: var(--ion-color-primary-dark), //darker
  contrast: (
    100: white,
    500: white,
    700: white,
  )
);

$my-primary: mat-palette($primary-palette, 500, 100, 700);
//$my-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
//$my-warn: mat.define-palette(mat.$red-palette);

$light-theme: mat-light-theme($my-primary, $my-primary);
$dark-theme: mat-dark-theme($my-primary, $my-primary);

@include angular-material-theme($light-theme);

body.dark-theme {
  @include angular-material-theme($dark-theme);
}
