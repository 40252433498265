button.v2 {
    color: var(--text-color);
    font-size: var(--ion-font-size);
    font-weight: 500;
    border-radius: 10px;
    white-space: nowrap;
    min-width: 64px;
    line-height: 36px;
    position: relative; /* Allow to Inject spinner with the text */
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s, transform 0.1s;
    padding: 0 10px;
  
    &:hover {
      background-color: var(--text-color-contrast);
      cursor: pointer;
    }
  
    &:active {
      transform: scale(0.95);
    }
  
    &.loading {
      pointer-events: none; /* Prevent interactions when loading */
    }
  
    &.loading::before { /* Inject spinner before text on loading */
      content: '';
      box-sizing: border-box;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      margin-right: 0.5em;
      border: 2px solid var(--text-color-contrast);
      border-top-color: var(--text-color);
      animation: spinner .8s linear infinite;
    }
  
    &.primary {
      color: var(--ion-color-primary-contrast);
      background: var(--ion-color-primary);
  
      &:hover {
        background-color: var(--ion-color-primary-shade);
      }
  
      &.loading {
        background-color: var(--ion-color-primary-shade);
      }
  
      &.loading::before {
        border: 2px solid var(--ion-background-secondary);
        border-top-color: var(--ion-color-primary-dark);
      }
    }
  
    &.danger {
      color: var(--ion-color-danger);
      background: var(--ion-background-color);
      border: 1px solid var(--ion-background-secondary);
  
      &:hover {
        color: var(--ion-color-danger-contrast);
        background-color: var(--ion-color-danger-shade);
      }
  
      &.loading {
        color: var(--ion-color-danger-contrast);
        background-color: var(--ion-color-danger-shade);
      }
  
      &.loading::before {
        border: 2px solid var(--ion-background-secondary);
        border-top-color: var(--ion-color-danger-dark);
      }
    }
  
    &.secondary {
      background: var(--ion-background-color);
      border: 1px solid var(--ion-background-secondary);
  
      &:hover {
        background-color: var(--ion-background-secondary);
        cursor: pointer;
      }
  
      &.loading {
        background-color: var(--ion-background-secondary);
      }
  
      &.loading::before {
        border: 2px solid var(--ion-background-secondary);
        border-top-color: var(--ion-color-danger-dark);
      }
    }
  
    &.icon {
      height: 32px;
      width: 32px;
      min-width: 0px;
      border-radius: 100%;
    }

    mat-icon {
        margin-right: 5px;
    }

    &.plain {
        background: transparent;
        border: none;

        &:hover {
          background-color: var(--text-color-contrast);
        }
    }
}

.info-box {
    .info-item {
        display: grid;
        grid-template-columns: 1fr 2fr;
        column-gap: 1em;
        .key {
            color: var(--ion-color-grey)
        }

        .value {
            font-weight: 500;
        }

        .cols-2 {
            grid-column: span 2;
        }
    }
}

.address-profile {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    width: 100%;
  
    .row1 {
        display: grid;
    }
  
    .tag {
        margin: 0;
    }
  
    .full-name {
        font-weight: 500;
    }
  
    .contact-details{
        color: var(--ion-color-grey);
        margin-bottom: 0.5em;
    }
  
    .address{
        white-space: pre;
        text-transform: capitalize;
    }
  
  
}

span.tag {
    margin: 0.5em 1em 0.5em 0;
    border-radius: 5px;
    text-transform: uppercase;
    font-size: var(--ion-font-size-s);
    padding: 5px 10px;
    width: fit-content;
    display: inline-block;
    white-space: nowrap;
    font-weight: 800;

    &[color="primary"] {
        background-color: rgba(90, 136, 255, 0.2);
        color: var(--ion-color-primary) !important;
    }

    &[color="warning"] {
        background-color: rgba(255, 123, 0, 0.1);
        color: #FF7A00 !important;
    }
      
    &[color="success"] {
        background-color: rgba(0, 156, 16, 0.2);
        color: #009C10 !important;
      }
      
    &[color="error"] {
        background-color: rgba(187, 2, 2, 0.2);
        color: #a20000 !important;
    }

    &[color="grey"] {
        background-color: rgb(135, 136, 138, 0.3);
        color: var(--ion-color-grey);
    }

    &[color="bronze"] {
        background-color: rgba(205, 127, 50, 0.3); /* Light bronze background with some opacity */
        color: #CD7F32 !important; /* Bronze color */
    }

    &[color="silver"] {
        background-color: rgba(192, 192, 192, 0.3); /* Light grey background with some opacity */
        color: #696969 !important; /* Silver color */
    }

    &[color="gold"] {
        background-color: rgba(252, 212, 1, 0.3); /* Improved contrast with increased opacity */
        color: #af9501 !important; /* Gold color with better contrast */
    }
}
  
body.dark-theme span.tag[color="primary"] {
background-color: rgba(90, 136, 255, 0.1);
color: rgb(152 193 217);
}